











import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class topSection extends Vue {
  async mounted() {}
}
