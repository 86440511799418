













import { Component, Vue } from "vue-property-decorator";

import interfaceService, { MenuItem } from "../services/Interface";

@Component
export default class TopNav extends Vue {
  async toHome() {
    interfaceService.scrollTo("#top-section");
  }
}
