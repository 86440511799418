
export default {
    email: 'paweljastrzebski96@gmail.com',
    phone: '+48 512818510',
    place: 'Nowy Tomyśl, Poznań, Poland',
    linkdin: 'linkedin.com/in/jastrzebski-pawel',
    linkdinFull: 'https://www.linkedin.com/in/jastrzebski-pawel',

    // envs
    sendToEmail:'paweljastrzebski96@gmail.com',
    // info https://smtpjs.com/
    tokenPerDomain: 'e1144077-77c1-4cbf-a477-087f61c2ce0a',

    G_ANALITICS_ID: "G-XB7M3D861W"
}