



















import { Component, Vue } from "vue-property-decorator";
import { Util } from "../Util";
import interfaceService, { MenuItem } from "../services/Interface";

@Component
export default class Menu extends Vue {
  
  items: MenuItem[] = interfaceService.items;

  async mounted() {
    await Util.wait(100);
  }
}
