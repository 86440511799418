

























import { Component, Vue } from "vue-property-decorator";

@Component
export default class topSection extends Vue {
  content: string = `
  Hi, I have been working hard to develop my programming skills since 2016. Why? I love it!
  I was lucky to find out quite early in my life what I love to do. So I set my goal to become a software developer. Unfortunately, no one is born with this skill, but know what? After a couple of years of working towards this goal, I find out that "If something is important to you, You will find a way. If not, You’ll find an excuse.". So I found a way.
  `;

  mounted() {}
}
