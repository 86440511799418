









import { Component, Vue } from "vue-property-decorator";
import { Util } from "../Util";

@Component
export default class BackgroundHero extends Vue {
  options = {
    background: {
      color: {
        value: "#000",
      },
    },
    fpsLimit: 40,
    particles: {
      number: {
        value: 200,
        density: {
          enable: true,
          value_area: window.innerWidth < 800 ? 2000 : 3800,
        },
      },
      color: {
        value: "#35e776",
      },
      shape: {
        type: "polygon",
        stroke: {
          width: 0,
          color: "#642BAB",
        },
        polygon: {
          nb_sides: 12,
        },
        image: {
          src: "img/github.svg",
          width: 100,
          height: 100,
        },
      },
      opacity: {
        value: 0.5,
        random: true,
        anim: {
          enable: true,
          speed: 0.5,
          opacity_min: 0,
          sync: false,
        },
      },
      size: {
        value: 9,
        random: true,
        anim: {
          enable: true,
          speed: 1,
          size_min: 0,
          sync: false,
        },
      },
      line_linked: {
        enable: false,
        distance: 112.2388442605866,
        color: "#0058fa",
        opacity: 0.16034120608655228,
        width: 2.5654592973848365,
      },
      move: {
        enable: true,
        speed: 1,
        direction: "none",
        random: true,
        straight: false,
        out_mode: "out",
        bounce: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200,
        },
      },
    },
    interactivity: {
      detect_on: "canvas",
      events: {
        onhover: {
          enable: true,
          mode: "repulse",
        },
        onclick: {
          enable: true,
          mode: "push",
        },
        resize: true,
      },
      modes: {
        grab: {
          distance: 400,
          line_linked: {
            opacity: 0.5,
          },
        },
        bubble: {
          distance: 400,
          size: 4,
          duration: 0.3,
          opacity: 1,
          speed: 3,
        },
        repulse: {
          distance: 120,
          duration: 20,
        },
        push: {
          particles_nb: 4,
        },
        remove: {
          particles_nb: 2,
        },
      },
    },
    retina_detect: true,
  };

  async mounted() {
    await Util.wait(100);
  }
}
