














import { Component, Vue } from "vue-property-decorator";
// @ts-ignore
import kursor from "kursor";
import AOS from "aos";
import { Util } from "./Util";

@Component({
  components: {},
  metaInfo: {
    title: "Paweł Jastrzębski",
    titleTemplate: "%s | Software Developer",
  },
})
export default class App extends Vue {
  mounted() {
    this.initCoursor();
    this.initAOS();
  }

  initCoursor() {
    new kursor({
      type: 2,
      removeDefaultCursor: true,
      // green --color-g
      color: "rgba(53,231,118, 0.8)",
    });
  }

  async initAOS() {
    await Util.wait(300);
    AOS.init({
      initClassName: "aos-init",
      animatedClassName: "aos-animate",
      easing: "ease-out",
    });
  }
}
