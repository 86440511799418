import Vue from 'vue';


export type Section = "#about-section" | "#top-section" | '#resume-section' | '#contact-section' | '#portfolio-section'

export interface MenuItem {
    name: string;
    selected: boolean;
    showOnSmallScreen: boolean;
    action?: () => any;
}


export class Interface {


    items: MenuItem[] = [
        {
            name: "Home",
            selected: true,
            showOnSmallScreen: true,
            action: () => {
                this.scrollTo("#top-section");
            },
        },
        {
            name: "About",
            selected: false,
            showOnSmallScreen: true,
            action: () => {
                this.scrollTo("#about-section", 'center');
            },
        },
        {
            name: "Resume",
            selected: false,
            showOnSmallScreen: true,
            action: () => {
                this.scrollTo("#resume-section", "nearest");
            },
        },
        {
            name: "Portfolio",
            showOnSmallScreen: false,
            selected: false,
            action: () => {
                // Vue.$toast.success("Coming soon!");
                this.scrollTo("#portfolio-section", 'start');
            },
        },
        {
            name: "Contact",
            showOnSmallScreen: true,
            selected: false,
            action: () => {
                this.scrollTo("#contact-section");
            },
        },
    ];



    get appScroll() {
        return document.body
    }

    scrollTo(selector: Section, pos: ScrollLogicalPosition = 'nearest') {
        const element: HTMLElement = document.querySelector(selector);
        const y = element.getBoundingClientRect().top + this.appScroll.scrollTop
        element.scrollIntoView({behavior: 'smooth', block: pos})
    }

}

export default new Interface();