




























import { Component, Prop, Vue } from "vue-property-decorator";
import { Util } from "../Util";

type levels = 1 | 2 | 3 | 4 | 5;

const timings = [
  "cubic-bezier(0.28, 1.485, 0.445, 0.06)",
  "cubic-bezier(0.250, 0.895, 0.445, 0.060)",
  "cubic-bezier(0.970, 0.205, 0.445, 0.060)",
  "cubic-bezier(0.235, 0.465, 0.000, 0.815)",
];

@Component
export default class SkillBox extends Vue {
  progress: number = 0;
  levelName: string = "";

  transitionDuration: number = Util.getRandomInt(1500, 2000);
  transitionFunction: string = timings[Util.getRandomInt(0, 3)];

  progressMap = {
    1: 10,
    2: 30,
    3: 50,
    4: 70,
    5: 100,
  };

  levelMap = {
    1: "beginer",
    2: "average",
    3: "skiled",
    4: "specialist",
    5: "expert",
  };

  @Prop() name: string;
  @Prop() level: levels;
  @Prop() description: string;

  @Prop() increase: number;

  mounted() {
    this.progress = this.progressMap[this.level ?? 1] + (this.increase ?? 0);
    this.levelName = this.levelMap[this.level ?? 1];
  }
}
