import globals from '../globals';

export interface SmtpMessage {
    from: string,
    subject: string,
    body: string,
    name?: string | any
}

export default class SmtpSender {

    static async sendSmtp(message: SmtpMessage) {

        const response = await window.Email.send({
            SecureToken: globals.tokenPerDomain,
            To: globals.sendToEmail,
            From: globals.sendToEmail,
            Subject: message.subject,
            Body: ` 
            <h2> Contact Form </h2>
            <h3>Sent from website: ${window.location.href}</h3>
            <b>From: </b> <b>${message.name || message.from}</b> <br />
            <b>Body: </b>
            <pre>${message.body}</pre>
            <b>End Body: </b>
            `
        });

        // console.log('response: ', response);
        if (!response.toLowerCase().includes('ok')) throw new Error(response);
    }

    static async openMailBox(message: SmtpMessage) {

        await new Promise(r => setTimeout(r, 1000));
        const url = `mailto:${globals.sendToEmail}?subject=${message.subject}&body=${message.body}&from=${message.from}&name=${message.name}`;
        // console.log(url)
        window.open(url, '_self')
    }

    static async openMailBoxEmpty() {
        const url = `mailto:${globals.sendToEmail}`;
        window.open(url, '_self')
    }

    static async openCallTo(number: string) {
        window.open(`tel:${number}`, '_self');
    }
}