




















import { Component, Vue } from "vue-property-decorator";

@Component
export default class ThanksSection extends Vue {
  thanks: string[] = [
    "<b>Igor Sowiński</b> for three years of great cooperation, thanks to which I learned consistency, perseverance, and that here and now is the best time to work really hard to make your dream come true.",
    "For <b>Przemysław Wieczorek</b> for help in learning Hibernate and support on the way to becoming a programmer.",
    "To <b>Paweł Warczyński</b> for his time to discuss the Domain-driven design architecture and invitation to Allegro.",
  ];

  mounted() {}
}
