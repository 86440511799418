





























import { Component, Vue } from "vue-property-decorator";
import interfaceService from "../services/Interface";
import Typed from "typed.js";

@Component
export default class topSection extends Vue {
  private typedProfession: Typed;

  mounted() {
    this.typedProfession = new Typed(".typed-profession", {
      strings: [
        "Software Developer",
        // "Front-End Engineer",
        // "UX/UI Designer",
        "Back-End Engineer",
        "Full-Stack Developer",
      ],
      typeSpeed: 80,
      backSpeed: 30,
      backDelay: 1800,
      startDelay: 0,
      loop: true,
    });
  }

  scrollDown() {
    interfaceService.scrollTo("#about-section");
  }
}
