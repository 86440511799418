import Vue from 'vue'
import App from './App.vue'
//@ts-ignore
import Background from './components/Background.vue'
import Menu from './components/Menu.vue'
import Header from './components/Header.vue'
import SubHeader from './components/SubHeader.vue'
import ResumePart from './components/ResumePart.vue'
import TopNav from './components/TopNav.vue'
import ShowMoreText from './components/ShowMoreText.vue'
import TopSection from './views/TopSection.vue'
import BottomSection from './views/BottomSection.vue'
import AboutSection from './views/AboutSection.vue'
import ContactSection from './views/ContactSection.vue'
import ResumeSection from './views/ResumeSection.vue'
import SkillsSection from './views/SkillsSection.vue'
import ThanksSection from './views/ThanksSection.vue'
import PortfolioSection from './views/PortfolioSection.vue'
import SkillBox from './components/SkillBox.vue'
import Particles from "particles.vue";
import VueMeta from 'vue-meta'
import Icon from 'vue-materials-icon/MaterialIcon.vue'
import VueToast from 'vue-toast-notification';
import FixedHeader from 'vue-fixed-header'
// @ts-ignore
import VueAgile from 'vue-agile'

Vue.config.productionTip = false

import 'material-icons/iconfont/material-icons.css';
import 'aos/dist/aos.css';
import 'vue-toast-notification/dist/theme-sugar.css';

// packages
Vue.component('icon', Icon);
Vue.component('vue-fixed-header', FixedHeader);

Vue.use(VueAgile)
Vue.use(Particles);
Vue.use(VueMeta)
Vue.use(VueToast, {
  position: 'bottom'
});

// global
Vue.component("background-hero", Background)
Vue.component("top-menu", Menu)
Vue.component("ShowMoreText", ShowMoreText)
Vue.component("section-header", Header)
Vue.component("section-sub-header", SubHeader)
Vue.component("bottom-section", BottomSection)
Vue.component("top-section", TopSection)
Vue.component("top-nav", TopNav)
Vue.component("about-section", AboutSection)
Vue.component("contact-section", ContactSection)
Vue.component("skills-section", SkillsSection)
Vue.component("thanks-section", ThanksSection)
Vue.component("portfolio-section", PortfolioSection)
Vue.component("skill-box", SkillBox)
Vue.component("resume-section", ResumeSection)
Vue.component("resume-part", ResumePart)

new Vue({
  render: h => h(App),
}).$mount('#app')


// analitics
import Analitics from "./services/Analitics";
var analitics = new Analitics()
