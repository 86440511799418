

























































import { Component, Vue } from "vue-property-decorator";

interface Project {
  name: string;
  year: number;
  category: string;
  description: string;
  url?: string;
  buttonTitle?: string;
  url1?: string;
  buttonTitle1?: string;
  images: string[];
  authors?: string;
  technologies: string;
}

@Component
export default class PortfolioSection extends Vue {
  thanks: Project[] = [
    {
      name: "MovieMemorize",
      year: 2021,
      category: "Application",
      description:
        "Project developed since 2019. Developed to help learn languages more efficiently by watching movies. Available for desktops, mobile and TVs.",
      images: ["moviememorize_1.jpg"],
      authors: "Paweł Jastrzębski, Igor Sowiński",
      technologies: "Spring, Angular, Electron, React Native",
      url:
        "https://moviememorize.com",
      buttonTitle: "Open Website",
    },
    {
      name: "Game 2048",
      year: 2016,
      category: "Game",
      description:
        "The game was created in 2016 in JavaScript and jQuery. This game was very popular at the time, so I decided to implement my own version to improve my JavaScript skills.",
      images: ["2048_2.png"],
      url: "https://jastrzebskipawel-2048.web.app",
      buttonTitle: "Play Now!",
      technologies: "JavaScript, jQuery",
    },
    {
      name: "CardValidator",
      year: 2018,
      category: "Web Application",
      description:
        "A Simple credit card validator built in microservices architecture. Project implemented as part of the Java course.",
      images: ["cardvalidator_1.jpg"],
      authors: "Paweł Jastrzębski, Igor Sowiński",
      technologies: "React, Spring",
      url:
        "https://www.figma.com/proto/glu24BgAqa82qw9qeoSgkl54/Card-Validator-Page-Design?node-id=1%3A2&scaling=min-zoom&page-id=0%3A1",
      buttonTitle: "Open Prototype",
      url1: "https://github.com/PawelJastrzebski/CardValidator_Microservices",
      buttonTitle1: "Github",
    },
    {
      name: "TextJet",
      year: 2019,
      category: "Mobile Application",
      description:
        "Hybrid mobile communicator developed as final project during SDA Academy course Java From Scratch. Front-end build in Angular and Cordova. ",
      images: ["textjet_1.jpg"],
      authors: "Paweł Jastrzębski, Igor Sowiński",
      technologies: "Angular, Cordova, Spring, WebSockets",
      url:
        "https://www.figma.com/proto/bHOCHtAXxN9NrxBeYqbfQG/TEXT-JET---Design?node-id=3%3A3&scaling=min-zoom&page-id=0%3A1",
      buttonTitle: "Open Prototype",
    },
    {
      name: "Alphabet",
      year: 2015,
      category: "Application",
      description:
        "A simple application that facilitates the learning of the letters of the alphabet with Polish pronunciation. Developed mainly for my little brother to help with learning.",
      images: ["alphabet_1.jpg"],
      url: "https://jastrzebskipawel-alphabet.web.app",
      buttonTitle: "Open website",
      technologies: "JavaScript",
    },
  ];

  mounted() {}

  openUrl(url: string) {
    if (!url) return;
    window.open(url, "__blank");
  }
}
