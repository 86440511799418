









































































import { Component, Vue } from "vue-property-decorator";
import { Util } from "../Util";
// @ts-ignore
import { jarallax } from "jarallax";
import globals from "../globals";
import SmtpSender from "../services/SmtpSender";

@Component({})
export default class ContactSection extends Vue {
  email = "";
  message = "";

  g = globals;

  async mounted() {
    await Util.wait(100);
    jarallax(document.querySelectorAll("#contact-baner"), {
      speed: 0.2,
      disableParallax: /iPad|iPhone|iPod|Android/,
      disableVideo: /iPad|iPhone|iPod|Android/,
    });
  }

  onEmailMe() {
    SmtpSender.openMailBoxEmpty();
  }
  onCallMe() {
    SmtpSender.openCallTo(globals.phone);
  }
  onLikedIn() {
    window.open(globals.linkdinFull);
  }

  async onSubmit(e: Event) {
    e.preventDefault();

    if (Util.isBlank(this.email)) {
      Vue.$toast.error("Enter your email");
      return;
    }

    if (Util.isBlank(this.message)) {
      Vue.$toast.success("Almost done, write something");
      return;
    }

    const message = {
      from: this.email,
      subject: "Portfolio Paweł Jastrzebski - New Message!",
      body: this.message,
      name: this.email,
    };
    try {
      await SmtpSender.sendSmtp(message);
      this.email = "";
      this.message = "";
      Vue.$toast.success("Thank you!");
    } catch (e) {
      console.warn(e);
      await SmtpSender.openMailBox(message);
    }
  }
}
