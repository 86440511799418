
import globals from '../globals';
// @ts-ignore
import gtag, {install} from 'ga-gtag';

export default class Analitics {

    constructor() {
        install(globals.G_ANALITICS_ID);
    }

    public async page(name: string) {
        this.track("page_view", { title: name });
      }
    
      public async track(event: string, data: object) {
        setTimeout(() => {
          try {
            const payload = Object.assign(data, { event: event });
            gtag('event', event, payload);
          } catch (e) {
            console.warn(event, e);
    
          }
        }, 500)
      }

}