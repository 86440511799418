






































































































import { Component, Vue } from "vue-property-decorator";
import globals from "../globals";

@Component
export default class ResumeSection extends Vue {
  content: string = ``;

  g = globals;

  mounted() {}
}
