






























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Menu extends Vue {
  pointsList: string[] = [];
  addDeley: number = 50;

  @Prop() header: string;
  @Prop() subHeader: string;
  @Prop() description: string;
  @Prop() date: string;
  @Prop() points: string[];

  @Prop() deley: number;

  mounted() {
    this.pointsList = this.points ?? [];
    this.addDeley = +(this.deley ?? 0);
  }
}
