












import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ShowMoreText extends Vue {
  @Prop() text: string;
  @Prop() show: number;

  showChars: number;
  textValue: string = "";
  showFullValue = false;

  mounted() {
    this.showChars = this.show ?? 3;
    this.textValue = this.text ?? "";
  }

  get showPart() {
    return this.textValue.substr(0, this.showChars);
  }

  get full() {
    return this.textValue;
  }

  showFull() {
    this.showFullValue = true;
  }
}
