import { render, staticRenderFns } from "./PortfolioSection.vue?vue&type=template&id=2fe5c588&scoped=true&"
import script from "./PortfolioSection.vue?vue&type=script&lang=ts&"
export * from "./PortfolioSection.vue?vue&type=script&lang=ts&"
import style0 from "./PortfolioSection.vue?vue&type=style&index=0&id=2fe5c588&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fe5c588",
  null
  
)

export default component.exports